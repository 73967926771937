


$(window).on('load', function(){ 
  $('.loader').fadeOut();
});

// Scroll to Top Button

$(document).ready(function(){

  $(window).scroll(function(){
    if($(this).scrollTop() > 700){
      $('#topBtn').fadeIn();
    } else{
      $('#topBtn').fadeOut();
    }
  });

  $("#topBtn").click(function(){
    $('html ,body').animate({scrollTop : 0},800);
  });
});



// Pop Up Video

$(document).ready(function() {
  $('.popup-youtube, .play, .popup-vimeo, .popup-gmaps').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      preloader: true,
  });
});



// wow.js

new WOW().init();

// Tilt.js

$('.js-tilt').tilt({
  maxTilt: 2,
  glare: true,
  maxGlare: .3
});
$('.js-tilt1').tilt({
  maxTilt: 2,
  glare: true,
  maxGlare: .3
});
$('.js-tilt2').tilt({
  maxTilt: 2,
  glare: true,
  maxGlare: .3
});

// counterup

$('.counter').counterUp({
    delay: 20,
    time: 2000,
});

// slick slider

$(document).ready(function(){
   $('.your-class').slick({
     centerMode: true,
     centerPadding: '0',
     slidesToShow: 1,
     slidesToScroll: 1,
     autoplay: false,
     dots: true,
     autoplaySpeed: 3000,
     arrows: true,
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           arrows: true,
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           arrows: false,
         }
       },
       {
         breakpoint: 768,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           arrows: false,
         }
       },
       {
         breakpoint: 600,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           arrows: false,
           dots: true,
         }
       },
     ],
     prevArrow:"<img class='a-left control-c prev slick-prev' src='assets/img/long-arrow-right.png'>",
     nextArrow:"<img class='a-right control-c next slick-next' src='assets/img/long-arrow-left.png'>",
     prevArrow:"<img class='a-left control-c prev slick-prev' src='../assets/img/long-arrow-right.png'>",
     nextArrow:"<img class='a-right control-c next slick-next' src='../assets/img/long-arrow-left.png'>"
   });
 });


 // slick slider

 $(document).ready(function(){
    $('.clients').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      centerPadding: 260,
      autoplay: false,
      dots: false,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
          }
        },
      ],
    });
  });


 // Testimonails Slider

$(document).ready(function() {
  $("#testimonial").owlCarousel({
    items:1,
    loop:true,
    autoplay:false,
    nav:true,
    navText: ["<img src='assets/img/home3/long-arrow-left.png'>","<img src='assets/img/home3/long-arrow-right.png'>"],
    navText: ["<img src='../assets/img/home3/long-arrow-left.png'>","<img src='../assets/img/home3/long-arrow-right.png'>"],
    dots: false,
  });
});


// Testimonails Slider

$(document).ready(function() {
  $("#clients").owlCarousel({
    items: 5,
     loop:true,
     nav: false,
     margin: 105,
     dots: false,
  	 autoHeight: true,
  	 smartSpeed: 3000,
  	 autoplay: 500,
     navText: ["<i class='fa fa-2x fa-long-arrow-left'></i>","<i class='fa fa-2x fa-long-arrow-right'></i>"],
     responsive:{
       0:{
           items:1,
       },
       768:{
           items:2,
       },
       992:{
           items:3,
       },
       1024:{
           items:5,
     }
   }
  });
});